import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;

if (!API_URL) {
  throw new Error(
    "REACT_APP_API_URL is not defined in the environment variables"
  );
}

if (!AUTH_TOKEN) {
  throw new Error(
    "REACT_APP_AUTH_TOKEN is not defined in the environment variables"
  );
}

// Helper function to determine max tokens for the selected model
const getModelParameters = (model) => {
  let params = {
    max_tokens: 4096, // Default for non-o1 series
  };

  switch (model) {
    case "o1-preview":
      params.max_completion_tokens = 32768; // for o1-preview
      break;
    case "o1-mini":
      params.max_completion_tokens = 65536; // for o1-mini
      break;
    case "gpt-4o":
      params.max_tokens = 4096; // Keeping 4096 for gpt-4o
      break;
    default:
      params.max_tokens = 25000; // Default for other models
      break;
  }

  return params;
};

export const sendChatMessage = async (messages, model = "gpt-4o") => {
  try {
    const modelParams = getModelParameters(model);

    const requestBody = {
      model,
      messages,
      temperature: 1,
      user: "open-ai-chat-ui",
      stream: false,
      helicone: {
        "Helicone-Property-App": "open-ai-chat-ui",
        "Helicone-Property-Environment": "development",
      },
    };

    // If the model uses `max_completion_tokens`, include it in the request
    if (modelParams.max_completion_tokens) {
      requestBody.max_completion_tokens = modelParams.max_completion_tokens;
    } else {
      requestBody.max_tokens = modelParams.max_tokens;
    }

    const response = await axios.post(
      `${API_URL}/v1/chat`,
      requestBody,
      {
        headers: {
          Authorization: `Bearer ${AUTH_TOKEN}`,
        },
      }
    );
    
    return {
      role: "assistant",
      content: response.data.content,
    };
  } catch (error) {
    console.error("Error in sendChatMessage:", error);
    throw error;
  }
};
