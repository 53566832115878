import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  List,
  ListItem,
  Avatar,
  IconButton,
  AppBar,
  Toolbar,
  Switch,
  MenuItem,
  FormControl,
  Select,
  Drawer,
  CircularProgress,
} from "@mui/material";
import {
  Menu as MenuIcon,
  ContentCopy as CopyIcon,
  Refresh as RefreshIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import remarkGfm from "remark-gfm";
import "katex/dist/katex.min.css";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EditIcon from "@mui/icons-material/Edit";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import ThreeBackground from "./ThreeBackground.js"; // Three.js background component
import { sendChatMessage } from "./chat.js";

const MAX_WORDS = 80000;

const App = () => {
  const [input, setInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [editingIndex, setEditingIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [model, setModel] = useState("o1-preview");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [pastConvos, setPastConvos] = useState([]);
  const inputRef = useRef(null);

  const theme = createTheme({
    palette: {
      mode: darkMode ? "dark" : "light",
    },
    breakpoints: {
      values: {
        xs: 0,     // Extra small devices (phones)
        sm: 600,   // Small devices (tablets)
        md: 900,   // Medium devices (small laptops)
        lg: 1200,  // Large devices (desktops)
        xl: 1536,  // Extra large devices (large desktops)
      },
    },
  });

  

  useEffect(() => {
    const storedConvos = Object.keys(localStorage)
      .filter((key) => key.startsWith("conversation_"))
      .map((key) => ({
        name: key,
        content: JSON.parse(localStorage.getItem(key)),
      }));
    setPastConvos(storedConvos);
  }, []);

  const countWords = (messages) => {
    return messages.reduce(
      (total, msg) => total + msg.content.split(/\s+/).length,
      0
    );
  };

  const enforceWordLimit = (messages) => {
    console.log("messages", messages);
    let currentWords = countWords(messages);
    while (currentWords > MAX_WORDS) {
      messages.shift();
      currentWords = countWords(messages);
    }
    return messages;
  };

  const handleSend = async () => {
    if (!input.trim()) return;

    const newMessage = { role: "user", content: input };
    setConversation((prev) => [...prev, newMessage]);

    setLoading(true);

    try {
      const botMessage = await sendChatMessage(
        [...conversation, newMessage],
        model
      );
      const updatedConversation = enforceWordLimit([
        ...conversation,
        newMessage,
        botMessage,
      ]);
      setConversation(updatedConversation);

      saveConversation(updatedConversation);
    } catch (error) {
      console.error("Error sending message:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setLoading(false);
      setInput("");
    }
  };

  const handleEditMessage = (index) => {
    setEditingIndex(index);
  };

  const handleSaveEdit = async (index) => {
    setLoading(true);
    try {
      const updatedConversation = conversation.slice(0, index + 1);

      const botMessage = await sendChatMessage(updatedConversation, model);
      updatedConversation.push(botMessage);
      setConversation(enforceWordLimit(updatedConversation));

      setEditingIndex(null);
      saveConversation(updatedConversation);
    } catch (error) {
      console.error("Error during editing:", error);
    } finally {
      setLoading(false);
    }
  };

  const regenerateResponse = async (index) => {
    const updatedConversation = conversation.slice(0, index + 1);

    setLoading(true);

    try {
      const botMessage = await sendChatMessage(updatedConversation, model);

      const existingAssistantIndex = updatedConversation.findIndex(
        (msg, idx) =>
          msg.role === "assistant" && idx === updatedConversation.length - 1
      );
      if (existingAssistantIndex !== -1) {
        updatedConversation.splice(existingAssistantIndex, 1);
      }

      updatedConversation.push(botMessage);
      setConversation(enforceWordLimit(updatedConversation));

      saveConversation(updatedConversation);
    } catch (error) {
      console.error("Error regenerating response:", error);
    } finally {
      setLoading(false);
    }
  };

  const saveConversation = (updatedConversation) => {
    const convoName = `conversation_${new Date()
      .toISOString()
      .replace(/:/g, "-")}`;
    localStorage.setItem(convoName, JSON.stringify(updatedConversation));

    const storedConvos = Object.keys(localStorage)
      .filter((key) => key.startsWith("conversation_"))
      .map((key) => ({
        name: key,
        content: JSON.parse(localStorage.getItem(key)),
      }));
    setPastConvos(storedConvos);
  };

  const loadConversation = (convoName) => {
    const convo = JSON.parse(localStorage.getItem(convoName));
    setConversation(convo);
  };

  const copyTableToClipboard = (content) => {
    const el = document.createElement("textarea");
    el.value = content;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Table content copied to clipboard!");
  };

  const copyToClipboard = (code) => {
    navigator.clipboard.writeText(code);
  };

  const preprocessMathContent = (content) => {
    return content
      .replace(/\\\(/g, "$")
      .replace(/\\\)/g, "$")
      .replace(/\\\[/g, "$$")
      .replace(/\\\]/g, "$$");
  };

  const renderMarkdownWithMath = (content, theme) => {
    const processedContent = preprocessMathContent(content);

    return (
      <ReactMarkdown
        remarkPlugins={[remarkMath, remarkGfm]}
        rehypePlugins={[[rehypeKatex, { strict: false }]]}
        components={{
          code({ node, inline, className, children, ...props }) {
            const match = /language-(\w+)/.exec(className || "");
            return !inline && match ? (
              <Box sx={{ position: "relative", mb: 0 }}>
                <SyntaxHighlighter
                  style={materialDark}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                >
                  {String(children).replace(/\n$/, "")}
                </SyntaxHighlighter>
                <IconButton
                  onClick={() => copyToClipboard(String(children))}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                >
                  <CopyIcon />
                </IconButton>
              </Box>
            ) : (
              <code className={className} {...props}>
                {children}
              </code>
            );
          },
          table({ children }) {
            // Convert children to an array safely
            const tableContent = React.Children.toArray(children)
              .map((row) =>
                React.Children.toArray(row.props.children)
                  .map((cell) =>
                    React.Children.toArray(cell.props.children).join("\t")
                  )
                  .join("\t")
              )
              .join("\n");

            return (
              <Box
                sx={{
                  position: "relative", // Set relative positioning for the container
                  display: "inline-block", // Adjust as needed
                  mb: 2,
                }}
              >
                <TableContainer
                  component={Paper}
                  sx={{
                    border: `1px solid ${
                      theme.palette.mode === "dark"
                        ? theme.palette.grey[800]
                        : theme.palette.grey[300]
                    }`,
                    "& table": {
                      borderCollapse: "collapse",
                      textAlign: "center",
                    },
                    "& td, th": {
                      border: `1px solid ${
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[700]
                          : theme.palette.grey[300]
                      }`,
                      padding: "8px 16px",
                      transition: "background-color 0.3s ease",
                    },
                    "& tr:hover": {
                      backgroundColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.grey[900]
                          : theme.palette.grey[200],
                    },
                  }}
                >
                  <Table>{children}</Table>
                </TableContainer>
                {/* Move the IconButton inside the Box and position it absolutely */}
                <IconButton
                  onClick={() => copyTableToClipboard(tableContent)}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 0,
                    backgroundColor: theme.palette.background.paper,
                    "&:hover": {
                      backgroundColor: theme.palette.action.hover,
                    },
                  }}
                  size="small" // Adjust size if necessary
                >
                  <CopyIcon fontSize="small" />
                </IconButton>
              </Box>
            );
          },
        }}
      >
        {processedContent}
      </ReactMarkdown>
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
          minHeight: "100vh",
          position: "relative",
        }}
      >
        <ThreeBackground />

        <AppBar
          position="static"
          sx={{
            backdropFilter: "blur(10px)",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            bgcolor: "rgba(255, 255, 255, 0.6)",
            zIndex: 10,
            color: "inherit",
          }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={() => setDrawerOpen(true)}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              Chat
            </Typography>
            <FormControl
              variant="outlined"
              size="small"
              sx={{
                minWidth: 120,
                bgcolor: "background.default",
                borderRadius: 1,
                mr: 2,
              }}
            >
              <Select
                value={model}
                onChange={(e) => setModel(e.target.value)}
                sx={{ color: "text.primary" }}
              >
                <MenuItem value="o1-preview">o1-preview</MenuItem>
                <MenuItem value="o1-mini">o1-mini</MenuItem>
                <MenuItem value="gpt-4o">gpt-4o</MenuItem>
              </Select>
            </FormControl>
            <Switch
              checked={darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
          </Toolbar>
        </AppBar>

        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Box sx={{ width: 250, padding: 2 }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                setConversation([]);
              }}
            >
              New Conversation
            </Button>

            <Typography variant="h6" sx={{ mt: 2 }}>
              Past Conversations
            </Typography>
            <List>
              {pastConvos.length > 0 ? (
                pastConvos.map((convo, index) => (
                  <ListItem
                    button
                    key={index}
                    onClick={() => loadConversation(convo.name)}
                  >
                    {convo.name}
                  </ListItem>
                ))
              ) : (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  No previous conversations found.
                </Typography>
              )}
            </List>
          </Box>
        </Drawer>

        <Container
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            pt: 2,
            pb: 2,
            pr: 2,
            //width: "60%",
            width:{
              xs: "100%", // Full width on extra small devices
              sm: "90%", // almost width on small devices
              md: "60%",  // 60% width on medium devices
              lg: "60%",  // 60% width on large devices
              xl: "60%",  // 60% width on extra large devices
            },
            zIndex: 10,
            margin: "0 auto",
          }}
        >
          <Box sx={{ flexGrow: 1, overflowY: "auto", overflowX: "none", mb: 8 }}>
            <List
              sx={{
                width: "100%",
                bgcolor: "none",
                borderRadius: 2,
                boxShadow: 0,
              }}
            >
              {conversation.map((msg, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    borderRadius: 2,
                    mb: 1,
                    p: 2,
                    width: "100%",
                    textWrap: "balance",
                    backdropFilter: "blur(10px)",
                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                    bgcolor:
                      msg.role === "user"
                        ? "rgba(255, 255, 255, 0.6)"
                        : "rgba(255, 255, 255, 0.3)",
                    border:
                      msg.role === "user"
                        ? "1px solid rgba(255, 255, 255, 0.8)"
                        : "1px solid rgba(255, 255, 255, 0.5)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: "99%",
                    }}
                  >
                    <Avatar
                      sx={{ mr: 2 }}
                      alt={msg.role === "user" ? "User" : "AI"}
                      src={
                        msg.role === "user"
                          ? "/user-placeholder.png"
                          : "/ai-placeholder.png"
                      }
                    />
                    {msg.role === "user" && editingIndex === index ? (
                      <TextField
                        value={msg.content}
                        onChange={(e) => {
                          const updatedConversation = [...conversation];
                          updatedConversation[index].content = e.target.value;
                          setConversation(updatedConversation);
                        }}
                        fullWidth
                        multiline
                        sx={{ mr: 2 }}
                      />
                    ) : (
                      <Box display="flex" flexDirection="column" width="90%">
                        <Typography
                          variant="body1"
                          sx={{
                            fontWeight: msg.role === "user" ? "bold" : "normal",
                          }}
                        >
                          {msg.role === "user" ? "You:" : "Assistant:"}
                        </Typography>
                        {renderMarkdownWithMath(msg.content, theme)}

                        {msg.role === "assistant" && !loading && (
                          <Box>
                            <IconButton
                              size="small"
                              onClick={() => copyToClipboard(msg.content)}
                              sx={{ ml: 0 }}
                            >
                              <CopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              size="small"
                              onClick={() => regenerateResponse(index)}
                              sx={{ ml: 0 }}
                            >
                              <RefreshIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Box>
                  {msg.role === "user" && (
                    <IconButton
                      size="small"
                      onClick={() =>
                        editingIndex === index
                          ? handleSaveEdit(index)
                          : handleEditMessage(index)
                      }
                      sx={{ alignSelf: "flex-start" }}
                    >
                      {editingIndex === index ? (
                        <SendIcon fontSize="small" />
                      ) : (
                        <EditIcon fontSize="small" />
                      )}
                    </IconButton>
                  )}
                </ListItem>
              ))}
              {loading && (
                <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
                  <CircularProgress />
                </Box>
              )}
            </List>
          </Box>

          <Box
            sx={{
              position: "fixed",
              bottom: 0,
              left: {
                xs: "0%", // Full width on extra small devices
                sm: "15%", // almost width on small devices
                md: "25%",  // 60% width on medium devices
                lg: "30%",  // 60% width on large devices
                xl: "30%",  // 60% width on extra large devices
              },
              right: {
                xs: "0%", // Full width on extra small devices
                sm: "15%", // almost width on small devices
                md: "25%",  // 60% width on medium devices
                lg: "30%",  // 60% width on large devices
                xl: "30%",  // 60% width on extra large devices
              },
              display: "flex",
              alignItems: "center",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.6)"
                  : "transparent",
              backdropFilter: "blur(10px)",
              p: 2,
              borderTop: "1px solid #ddd",
              zIndex: 10,
            }}
          >
            <TextField
              ref={inputRef}
              fullWidth
              multiline
              maxRows={6}
              placeholder="Type your message..."
              variant="outlined"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              disabled={loading}
              sx={{
                mr: 2,
                bgcolor:
                  theme.palette.mode === "light"
                    ? "rgba(255, 255, 255, 0.6)"
                    : "transparent",
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={
                editingIndex !== null
                  ? () => handleSaveEdit(editingIndex)
                  : handleSend
              }
              disabled={loading || !input.trim()}
            >
              ➤
            </Button>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default App;
