import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { gsap } from 'gsap';

const ThreeBackground = () => {
    const mountRef = useRef(null);
    const cameraRef = useRef(null);

    useEffect(() => {
        const mountNode = mountRef.current;  // Copy the ref to a local variable

        // Scene setup
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        cameraRef.current = camera; // Store the camera in the ref
        const renderer = new THREE.WebGLRenderer({ alpha: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setClearColor(0x000000, 0); // Transparent background

        // Attach the renderer to the DOM using the local variable
        if (mountNode) {
            mountNode.appendChild(renderer.domElement);
        }

        // Wave parameters
        const waveWidth = 50;
        const waveDepth = 3;
        const waveSegmentsX = 500;
        const waveSegmentsZ = 50;
        const waveSpeed = 0.015;  // Slower wave speed
        const waveHeight = 0.55;  // Larger wave height

        // Create the plane geometry and material
        const geometry = new THREE.PlaneGeometry(waveWidth, waveDepth, waveSegmentsX, waveSegmentsZ);
        const material = new THREE.ShaderMaterial({
            uniforms: {
                color1: { value: new THREE.Color(0x66A3FF) },
                color2: { value: new THREE.Color(0x0033FF) },
                lightColor: { value: new THREE.Color(0xffffff) },
                lightPosition: { value: new THREE.Vector3(20, 50, 10) },
                time: { value: 0 },
                flowSpeed: { value: 0.5 } // Controls the speed of the random waves
            },
            vertexShader: `
                uniform float time;
                uniform float flowSpeed;
                uniform vec3 lightPosition;
                varying vec2 vUv;
                varying float vWave;
                varying vec3 vNormal;
                varying vec3 vLightDirection;

                void main() {
                    vUv = uv;
                    vec3 pos = position;
                    
                    float wave1 = sin(pos.x * 2.0 + time) * sin(pos.y * 2.0 + time) * ${waveHeight.toFixed(1)};
                    float wave2 = cos(pos.x * 1.0 + time * 1.5) * cos(pos.y * 1.5 + time * 0.5) * ${waveHeight.toFixed(1)} * 0.5;
                    float wave3 = sin(pos.x * 4.0 - time * flowSpeed) * cos(pos.y * 2.0 - time * flowSpeed * 0.5) * ${waveHeight.toFixed(1)} * 0.3;
                    
                    pos.z = wave1 + wave2 + wave3;
                    vWave = pos.z;

                    vec3 transformedNormal = normalize(normalMatrix * vec3(0.0, 0.0, 1.0));
                    vNormal = transformedNormal;
                    vLightDirection = normalize(lightPosition - pos);
                    
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);
                }
            `,
            fragmentShader: `
                uniform vec3 color1;
                uniform vec3 color2;
                uniform vec3 lightColor;
                varying vec2 vUv;
                varying float vWave;
                varying vec3 vNormal;
                varying vec3 vLightDirection;

                void main() {
                    float mixRatio = smoothstep(0.0, 1.0, vUv.y);
                    vec3 color = mix(color1, color2, mixRatio);

                    float opacity = 0.1 + 0.9 * smoothstep(0.0, 1.0, abs(vWave));
                    float edgeFade = smoothstep(0.0, 0.2, vUv.x) * smoothstep(1.0, 0.8, vUv.x);
                    opacity *= edgeFade;

                    float specular = pow(max(dot(vNormal, vLightDirection), 0.0), 32.0);
                    vec3 finalColor = color + lightColor * specular;

                    gl_FragColor = vec4(finalColor, opacity);
                }
            `,
            transparent: true,
            side: THREE.DoubleSide
        });

        const wave = new THREE.Mesh(geometry, material);
        wave.rotation.x = -Math.PI / 2;
        scene.add(wave);

        camera.lookAt(0, 0, 0);

        const animateCamera = () => {
            gsap.to(camera.position, {
                x: -0.5,
                z: 5,
                duration: 150,  // Slow transition
                ease: "power1.inOut",
                onComplete: () => {
                    gsap.to(camera.position, {
                        x: 0,
                        z: 15,
                        duration: 150,
                        ease: "power1.inOut",
                        delay: 2.5,
                        onComplete: animateCamera
                    });
                },
                delay: 2.5
            });
        };

        animateCamera();

        function animate() {
            requestAnimationFrame(animate);
            material.uniforms.time.value += waveSpeed;
            renderer.render(scene, camera);
        }

        animate();

        window.addEventListener('resize', () => {
            renderer.setSize(window.innerWidth, window.innerHeight);
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
        });

        // Cleanup function
        return () => {
            if (mountNode) {
                mountNode.removeChild(renderer.domElement);
            }
        };
    }, []);

    return <div ref={mountRef} style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 0 }} />;
};

export default ThreeBackground;
